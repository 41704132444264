
import debounce from "lodash/debounce"
import { mapState, mapMutations } from "vuex"

export default {
  name: "Search",
  data: () => ({
    isLoading: false,
    items: [],
    search: null,
  }),
  computed: {
    ...mapState(["searchBar"]),
    filteredItems() {
      if (this.items?.length > 0) {
        const isPropertyLength =
          [...this.items?.[0]?.category].length > 0 ||
          [...this.items?.[0]?.products].length > 0

        return isPropertyLength
          ? [
              { header: this.$t("сategories"), disabled: true },
              { divider: true },
              ...this.items?.[0]?.category,
              { header: this.$t("products"), disabled: true },
              { divider: true },
              ...this.items?.[0]?.products,
            ]
          : []
      }
      return []
    },
  },
  watch: {
    search() {
      this.makeRequest()
    },
  },
  methods: {
    ...mapMutations({
      toggleSearch: "TOOGLESEARCH",
    }),
    makeRequest: debounce(function () {
      if (!this.search || this.search.length < 2) {
        this.items = []
        return
      }
      this.isLoading = true
      // Lazily load input items
      this.$axios
        .post(
          "/api/v2/search/p-n-c?search=",
          {
            perpage: 18,
            search: this.search,
            page: 1,
            lang: this.$i18n.locale,
          },
          { progress: false }
        )
        .then((res) => {
          if (this.items.length > 0) this.items = []
          this.items.push(res?.data?.data)
        })
        .catch((err) => {
          return err
        })
        .finally(() => {
          this.isLoading = false
          this.$gtm.push({ event: "view_search_results" })
        })
    }, 500),
    goToSearchPage() {
      this.$store.commit("TOOGLESEARCH")
      this.$router.push({
        path: "/search",
        query: { search: this.search, page: 1 },
      })
    },
  },
}
