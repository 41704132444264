export default {
  computed: {
    flag() {
      const locale = this.$i18n.locale
      if (locale === 'ru') {
        return process.env.STORAGE_GOOGLEAPIS + '/flags/ru.png'
      } else if (locale === 'en') {
        return process.env.STORAGE_GOOGLEAPIS + '/flags/gb.png'
      } else {
        return process.env.STORAGE_GOOGLEAPIS + '/flags/ua.png'
      }
    },
  },
  methods: {
    getPathFlags() {
      return process.env.STORAGE_GOOGLEAPIS + '/flags/'
    },
  },
}
