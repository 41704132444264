
export default {
  name: 'CabinetMenu',
  computed: {
    items() {
      return [
        {
          title: this.$t('my_profile'),
          icon: this.$vuetify.icons.values.accountOutline,
          to: '/my/profile',
          disabled: !this.$auth.loggedIn,
        },
        {
          title: this.$t('my_orders'),
          icon: this.$vuetify.icons.values.shoppingOutline,
          to: '/my/orders',
          disabled: !this.$auth.loggedIn,
        },
        {
          title: this.$t('list_of_comparisons'),
          icon: this.$vuetify.icons.values.compare,
          to: '/my/compare',
          disabled: false,
        },
        {
          title: this.$t('my_wish_list'),
          icon: this.$vuetify.icons.values.heartOutline,
          to: '/my/wishes',
          disabled: false,
        },
        /* {
          title: 'Мої відгуки',
          icon: mdiStarOutline,
          to: '/my/reviews',
          disabled: true,
        },
        {
          title: 'Мій дисконт',
          icon: mdiTicketPercentOutline,
          to: '/my/discount',
          disabled: true,
        }, */
      ]
    },
  },
}
