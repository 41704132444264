
import { mapActions, mapGetters } from "vuex"

export default {
  name: "ListCategories",
  computed: {
    ...mapGetters({
      Categories: "compare/listCategory",
    }),
  },
  methods: {
    ...mapActions("compare", ["toggleDialogCompare"]),
  },
}
