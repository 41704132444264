
export default {
  name: "OrderСalculationButton",
  data() {
    return {
      story: {},
      windowWidth: 0,
      windowHeight: 0,
    }
  },
  async fetch() {
    const res = await this.$storyapi.get(
      "cdn/stories/popups/calculate-order/index",
      {
        version: "published",
        language: this.$i18n.locale !== "uk" ? this.$i18n.locale : "Default",
        cv: Date.now(),
      }
    )
    this.story = res?.data?.story?.content
  },
  computed: {
    getTitle() {
      return this?.story?.title
    },
    getOpacity() {
      return this?.story?.opacity
    },
    getColor() {
      return this?.story?.buttonColor
    },
    getImage() {
      return this?.story?.image
    },
    isSmallScreen() {
      return this.windowHeight < 720
    },
  },
  mounted() {
    this.updateWindowSize()
    window.addEventListener("resize", this.updateWindowSize)
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.updateWindowSize)
  },
  methods: {
    goToPreOrder() {
      this.$router.push(this.$nuxt.localePath("/pages/pre_order"))
    },
    updateWindowSize() {
      this.windowWidth = window.innerWidth
      this.windowHeight = window.innerHeight
    },
  },
}
