
import { mapGetters, mapActions } from 'vuex'
import ListCategories from './listCategories'

export default {
  name: 'DialogCompare',
  components: { ListCategories },
  computed: {
    showCompareDialog: {
      get() {
        return this.dialogCompareState
      },
      set() {
        this.toggleDialogCompare()
      },
    },
    ...mapGetters({
      dialogCompareState: 'compare/dialogCompare',
    }),
  },
  methods: {
    ...mapActions('compare', ['toggleDialogCompare']),
  },
}
