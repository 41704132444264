
import { version } from '~/package.json'
import AppBar from '~/components/core/AppBar'
import DashboardCoreDrawer from '~/components/core/Drawer'
import DashboardCoreFooter from '~/components/core/Footer'
import PopupsDealer from '~/components/popups/dealer'
import orderCalcBtn from '~/components/core/orderСalculationButton.vue'
// import PopupsDiscount from '~/components/popups/discount'

export default {
  name: "DeafaultLayout",
  components: {
    // PopupsDiscount,
    DashboardCoreFooter,
    DashboardCoreDrawer,
    AppBar,
    PopupsDealer,
    orderCalcBtn,
  },
  notifications: {
    showToast: {},
  },
  data() {
    return {
      version,
      fab: false,
      show: false,
    }
  },
  head() {
    const i18nHead = this.$nuxtI18nHead({
      addDirAttribute: true,
      addSeoAttributes: true,
    })
    return {
      htmlAttrs: {
        ...i18nHead.htmlAttrs,
      },
      meta: [...i18nHead.meta],
      link: [...i18nHead.link],
    }
  },
  mounted() {
    if (this.$vuetify.breakpoint.lgAndUp) {
      this.$store.commit("products/setDesktopFilter", true)
    }
    this.subscriptions()
  },
  methods: {
    getName(item) {
      return (
        item?.trans_title ||
        item?.product?.trans_title + " - " + item?.characteristic?.trans_title
      )
      // item?.trans_title || item?.pickedItem?.option?.trans_title
    },
    subscriptions() {
      if (process.browser) {
        this.$store.subscribe((mutation, state) => {
          if (mutation.type === "cart/addToCart") {
            this.showToast({
              theme: "dark",
              type: "success",
              title: this.$t("added_to_cart"),
              titleColor: "white",
              message: this.getName(mutation?.payload),
              timeout: 3000,
              iconUrl: require("@mdi/svg/svg/cart-arrow-down.svg"),
              iconColor: "white",
              buttons: [],
            })
          }

          if (mutation.type === "cart/ADD_NEW_PRODUCT") {
            this.showToast({
              theme: "dark",
              type: "success",
              title: this.$t("added_to_cart"),
              titleColor: "white",
              message: this.getName(mutation?.payload),
              timeout: 3000,
              iconUrl: require("@mdi/svg/svg/cart-arrow-down.svg"),
              iconColor: "white",
              buttons: [],
            })
          }
          if (mutation.type === "cart/ADD_EXISTING_PRODUCT") {
            this.showToast({
              theme: "dark",
              type: "success",
              title: this.$t("increased_number"),
              titleColor: "white",
              message: this.getName(mutation?.payload),
              timeout: 3000,
              iconUrl: require("@mdi/svg/svg/cart-plus.svg"),
              iconColor: "white",
              buttons: [],
            })
          }
          if (mutation.type === "SHOWTOTOP") {
            this.show = state.showtotop
          }
          if (mutation.type === "cart/changeSelectedLength") {
            this.showToast({
              theme: "dark",
              type: "info",
              title: this.$t("length_changed"),
              titleColor: "white",
              message: this.getName(mutation?.payload),
              iconColor: "white",
              timeout: 3000,
              iconUrl: require("@mdi/svg/svg/check-bold.svg"),
              buttons: [],
            })
          }
          if (mutation.type === "forms/STATUS_CREATE_PRE_ORDER") {
            this.showToast({
              theme: "light",
              type: state.forms.success_pre_order ? "success" : "error",
              title: state.forms.success_pre_order
                ? this.$t("thank_you_for_contacting_us")
                : this.$t("error_log"),
              titleColor: "white",
              message: "",
              timeout: 15000,
              iconUrl: require("@mdi/svg/svg/alert-circle-outline.svg"),
              iconColor: "white",
              buttons: [],
            })
          }
          if (mutation.type === "forms/STATUS_INVITATION_DEALER") {
            this.showToast({
              theme: "light",
              type: state.forms.success_invitation_dealer ? "success" : "error",
              title: state.forms.success_invitation_dealer
                ? this.$t("thank_you_for_contacting_us")
                : this.$t("error_log"),
              titleColor: "white",
              message: "",
              timeout: 15000,
              iconUrl: require("@mdi/svg/svg/alert-circle-outline.svg"),
              iconColor: "white",
              buttons: [],
            })
          }
          // if (mutation.type === 'forms/STATUS_ASK_FOR_DISCOUNT') {
          //   this.showToast({
          //     theme: 'light',
          //     type: state.forms.success_ask_for_discountr ? 'success' : 'error',
          //     title: state.forms.success_ask_for_discountr
          //       ? this.$t('thank_you_for_contacting_us')
          //       : this.$t('error_log'),
          //     titleColor: 'white',
          //     message: '',
          //     timeout: 15000,
          //     iconUrl: require('@mdi/svg/svg/alert-circle-outline.svg'),
          //     iconColor: 'white',
          //     buttons: [],
          //   })
          // }
          if (mutation.type === "storyblok/ACCESS_STATUS") {
            this.showToast({
              theme: "light",
              type: state.storyblok.error ? "error" : "success",
              title: this.$t("error_storyblok"),
              titleColor: "white",
              message: "",
              timeout: 3000,
              iconUrl: require("@mdi/svg/svg/alert-circle-outline.svg"),
              iconColor: "white",
              buttons: [],
            })
          }
          if (
            mutation.type === "compare/ADD_NEW_CATEGORY" ||
            mutation.type === "compare/ADD_PRODUCT"
          ) {
            this.showToast({
              theme: "light",
              type: "success",
              title: this.$t("add_to_compare"),
              titleColor: "white",
              message: "",
              timeout: 2000,
              iconUrl: require("@mdi/svg/svg/alert-circle-outline.svg"),
              iconColor: "white",
              buttons: [],
            })
          }
          if (
            mutation.type === "compare/REMOVING_CATEGORY" ||
            mutation.type === "compare/REMOVE_PRODUCT"
          ) {
            this.showToast({
              theme: "light",
              type: "error",
              title: this.$t("remove_to_compare"),
              titleColor: "white",
              message: "",
              timeout: 2000,
              iconUrl: require("@mdi/svg/svg/alert-circle-outline.svg"),
              iconColor: "white",
              buttons: [],
            })
          }
          if (mutation.type === "favorites/ADD_NEW_FAVORITES") {
            this.showToast({
              theme: "light",
              type: "success",
              title: this.$t("add_to_favorites"),
              titleColor: "white",
              message: "",
              timeout: 2000,
              iconUrl: require("@mdi/svg/svg/alert-circle-outline.svg"),
              iconColor: "white",
              buttons: [],
            })
          }
          if (mutation.type === "favorites/REMOVE_FAVORITES") {
            this.showToast({
              theme: "light",
              type: "error",
              title: this.$t("remove_to_favorites"),
              titleColor: "white",
              message: "",
              timeout: 2000,
              iconUrl: require("@mdi/svg/svg/alert-circle-outline.svg"),
              iconColor: "white",
              buttons: [],
            })
          }
        })
      }
    },
    onScroll() {
      if (window.pageYOffset > 200) {
        this.$store.commit("SHOWTOTOP", true)
      } else {
        this.$store.commit("SHOWTOTOP", false)
      }
    },
    toTop() {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      })
    },
  },
}
