
// Utilities
import { mapState } from "vuex"
import pathFlags from "@/mixins/pathFlags"

export default {
  name: "DashboardCoreDrawer",
  mixins: [pathFlags],
  props: {
    expandOnHover: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    languages: [
      {
        name: "uk",
        flag: "ua",
        description: "Українська",
      },
      {
        name: "ru",
        flag: "ru",
        description: "Русский",
      },
      // {
      //   name: 'en',
      //   flag: 'gb',
      //   description: 'English',
      // },
    ],
    open: [],
    search: null,
    caseSensitive: false,
    tree: [],
  }),
  computed: {
    ...mapState(["barColor", "content/categories", "content/baseCategories"]),
    drawerWidth() {
      if (this.$vuetify.breakpoint.xl) {
        return "20%"
      } else if (this.$vuetify.breakpoint.lgAndUp) {
        return "25%"
      } else if (this.$vuetify.breakpoint.mdAndUp) {
        return "40%"
      } else if (this.$vuetify.breakpoint.smAndUp) {
        return "40%"
      } else {
        return "100%"
      }
    },
    filter() {
      return this.caseSensitive
        ? (item, search, textKey) => item[textKey].includes(search)
        : undefined
    },
    drawer: {
      get() {
        return this.$store.state.drawer
      },
      set(val) {
        this.$store.commit("setDrawer", val)
      },
    },
    computedCategories() {
      return this.$store.state.content.baseCategories.map(this.mapCategory)
    },
  },
  watch: {
    "$vuetify.breakpoint.smAndDown"(val) {
      this.$emit("update:expandOnHover", !val)
    },
    "$route.path"(val) {
      this.updateAll(false)
    },
  },
  mounted() {
    this.$store.dispatch("content/get_categories")
  },
  methods: {
    action(event) {
      this.$router.push(this.$nuxt.localePath(event[0].to))
    },
    updateAll(val) {
      this.$refs.derevo.updateAll(val)
    },
    mapCategory(item) {
      return {
        ...item,
        children:
          item?.children && item?.children.length !== 0
            ? item.children
                .filter((item) => {
                  return !(
                    item?.children?.length <= 0 &&
                    item.productsCount <= 0 &&
                    !item.static
                  )
                })
                .map(this.mapCategory)
            : undefined,
        title: item.trans_title ? item.trans_title : this.$t(item.title),
        to: this.categoryPathMaker(item),
        locked: this.categoryPathMaker(item) === this.$route.path,
      }
    },
    categoryPathMaker(cat) {
      if (cat.alias && cat.uses_options) {
        return "/cat/" + cat.alias + "/opt/1"
      } else if (cat.to && !("uses_options" in cat)) {
        return cat.to
      } else if (cat.alias && cat.productsCount > 0) {
        return "/cat/" + cat.alias + "/pro/1"
      } else {
        return "/not_found"
      }
    },
  },
}
