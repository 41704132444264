
import { mapState, mapMutations, mapGetters, mapActions } from "vuex"
import CabinetMenu from "../CabinetParts/CabinetMenu"
import Search from "./Search"
import DialogCompare from "./dialogCompare"
import MainPhones from "@/components/contactsParts/MainPhones"
import PhoneButton from "@/components/buttons/phone"
import pathFlags from "@/mixins/pathFlags"

export default {
  name: "DashboardCoreAppBar",
  components: {
    DialogCompare,
    MainPhones,
    CabinetMenu,
    Search,
    PhoneButton,
  },
  mixins: [pathFlags],
  notifications: {
    showToast: {},
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    story: {},
    active_menu: [],
    languages: [
      {
        name: "uk",
        flag: "ua",
        description: "Українська",
      },
      {
        name: "ru",
        flag: "ru",
        description: "Русский",
      },
      // {
      //   name: 'en',
      //   flag: 'gb',
      //   description: 'English',
      // },
    ],
    language: "uk",
  }),
  async fetch() {
    const res = await this.$storyapi.get("cdn/stories", {
      starts_with: "pages/contacts",
    })
    // Let's convert content.date from a String to a Date
    const page = res.data.stories.map((story) => {
      story.content.date = new Date(story.content.date)
      return story
    })
    this.story = page[0]
    return { page }
  },
  computed: {
    ...mapState(["drawer", "auth", "notifications", "cart", "searchBar"]),
    ...mapGetters({
      cart_count: "cart/getCartPositionCount",
      Categories: "compare/listCategory",
    }),
    sumAllProducts() {
      let sum = 0
      this.Categories.forEach((x) => {
        sum += x?.items?.length
      })

      return sum
    },
    showPhonesDialog: {
      get() {
        return this.$store.getters["buttons/dialogPhones"]
      },
      set() {
        this.$store.dispatch("buttons/toggleDialogPhones")
      },
    },
    baseCategories: {
      get() {
        return this.$store.state.content.baseCategories
      },
    },
  },
  watch: {
    $route() {
      this.active_menu = []
    },
  },
  methods: {
    ...mapActions("compare", ["toggleDialogCompare"]),
    ...mapMutations({
      toggleSearch: "TOOGLESEARCH",
    }),
    async logout() {
      await this.$auth.logout().then(() =>
        this.showToast({
          type: "info",
          title: this.$t("logout"),
          message: this.$t("logged_out"),
          timeout: 3000,
          iconUrl: this.$vuetify.icons.values.arrowLeft,
        })
      )
    },
    categoryPathMaker(cat) {
      if (cat.alias && cat.productsCount > 0 && cat.childrenCount === 0) {
        return "/cat/" + cat.alias + "/pro/1"
      } else if (
        cat?.alias &&
        cat?.uses_options === 1 &&
        cat?.children?.length === 0
      ) {
        return "/cat/" + cat?.alias + "/opt/1"
      } else if (cat?.alias && cat?.children?.length !== 0) {
        return "/cat/" + cat?.alias
      } else {
        return cat?.to
      }
    },
  },
}
