
export default {
  name: 'Dealer',
  data: () => ({
    story: {},
    width: 344,
    height: 200,
    delay: 5000,
    timeout: 15000,
    version: 0,
    destination_url: '',
    imgDealer: '',
  }),
  async fetch() {
    const storyapidealer = this.$device.isMobile
      ? 'cdn/stories/popups/dealer/mobile'
      : 'cdn/stories/popups/dealer/desktop'
    const res = await this.$storyapi.get(storyapidealer, {
      version: 'published',
      language: this.$i18n.locale !== 'uk' ? this.$i18n.locale : 'Default',
      cv: Date.now(),
    })
    this.story = res.data.story
    if (this.story && this.story?.content?.timeout !== 0) {
      this.timeout = this.story?.content?.timeout
    }
    if (this.story && this.story?.content?.destination_url !== 0) {
      this.destination_url = this.story?.content?.destination_url
    }
    if (this.story && this.story?.content?.image !== 0) {
      this.imgDealer = this.story?.content?.image.filename
    }
    if (this.story && this.story?.content?.height !== 0) {
      this.height = this.story?.content?.height
    }
    if (this.story && this.story?.content?.version !== 0) {
      this.version = this.story?.content?.version
    }
    if (this.story && this.story?.content?.max_width !== 0) {
      this.width = this.story?.content?.max_width
    }
    if (this.story && this.story?.content?.delay !== 0) {
      this.delay = this.story?.content?.delay
    }
  },
  computed: {
    showPopupDealerDialog: {
      get() {
        return this.$store.getters['showpopups/getDealerWindowState']
      },
      set() {
        this.$store.dispatch('showpopups/toggleWindowDealer')
      },
    },
  },
  mounted() {
    if (
      this.$store.getters['showpopups/getVersionPopupDealer'] !==
      this.version &&
      this.version !== 0
    ) {
      setTimeout(() => {
        this.$store.commit('showpopups/TOGGLE_POPUPS_DEALER')
        this.$store.commit('showpopups/SET_VERSION_POPUPS_DEALER', this.version)
      }, this.delay)
    }
  },
  methods: {
    closePopup() {
      this.$store.commit('showpopups/SET_VERSION_POPUPS_DEALER', this.version)
      this.$store.commit('showpopups/TURN_OFF_POPUPS_DEALER')
    },
  },
}
