
import { version } from "~/package.json"

export default {
  name: "DashboardCoreFooter",
  data: () => ({
    version,
    show: false,
  }),
  computed: {
    links() {
      return [
        {
          href: "https://www.facebook.com/astra.company.ua/",
          icon: this.$vuetify.icons.values.facebook,
          text: "Facebook",
        },
        {
          href: "https://www.linkedin.com/company/11480384",
          icon: this.$vuetify.icons.values.linkedin,
          text: "Linkedin",
        },
        {
          href: "https://www.instagram.com/astra.company.ua/",
          icon: this.$vuetify.icons.values.instagram,
          text: "Instagram",
        },
      ]
    },
  },
  methods: {
    openUrl(url) {
      window.open(url, "_blank")
    },
  },
}
